import * as React from 'react';
import Navbar from '../navbar/navbar';
import './about.css'
import HomeCarousel from '../components/carousel/carousel';
import Typography from '@mui/material/Typography';
import Footer from '../components/footer/footer';
import about from './about.png'
import CardsConsultoria from '../components/cardsConsultoria/cardsConsultoria';
import Agende from '../components/agende/agende';
import Missao from '../components/missao/missao';
import Time from '../components/time/time';



export default class About extends React.Component {
  render() {
    return (
      <><><Navbar></Navbar>
        <div class="about">
          <div class="content-about">
            <img id="img-about" src={about} alt="Sala de reunião com pessoas trabalhando"></img>
          </div>
          <div class="content-about">
            <Typography>Iniciamos nossa história como um grupo interessado em compreender com real profundidade a CAT (Testagem Adaptativa Computadorizada) e logo notamos a desigualdade no acesso a testes psicológicos e educacionais de qualidade por parte do público em geral.</Typography>
            <Typography>Isso ocorre porque a construção de um instrumento de qualidade e sua aplicação têm altos custos. Por isso, muitas vezes é financeiramente inviável acessar e se beneficiar do uso de um teste psicológico ou educacional.</Typography>
            <Typography>O resultado dessa desigualdade é que pessoas e instituições gastam horas de trabalho com testes, provas e admissões enviesadas e/ou imprecisas. Acreditamos no potencial dos testes em computador para reduzir esse problema.</Typography>
            <Typography>Nosso primeiro teste desenvolvido em computador mede os interesses profissionais de estudantes de psicologia e é aplicado em formato adaptativo (EIAPsi-CAT).</Typography>
            <Typography>Mas nós queremos ir além promovendo inovações e desenvolvendo as melhores soluções em testagem computadorizada com conhecimento científico de ponta. Nós queremos ampliar para a sociedade o acesso justo e democrático a esses avanços.</Typography>
            <Typography>O CATvante atua para que pesquisadores, estudantes de graduação e pós-graduação, psicólogos e educadores construam, apliquem e acessem testes de forma eficiente.</Typography>
            <Typography></Typography>
          </div>
        </div>
        <CardsConsultoria></CardsConsultoria>
        <Missao></Missao>


        <Time></Time>
        <HomeCarousel></HomeCarousel>

        <Agende></Agende>

        <Footer></Footer>


      </></>
    )
  }
}