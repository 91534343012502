import * as React from 'react';
import CardHome from '../cardHome/cardHome';
import './cardsConsultoria.css'
import cientifica from './cientifica.png'
import consultoria from './consultoria.png'
import comercializacao from './comercializacao.png'


export default class CardsConsultoria extends React.Component {
	render() {
		return (
			<div class="consultoria">
				<div class="consultoria-title">
					<h1>Nossos Serviços</h1>
					<p>Estudos científicos, consultoria para empresas e comercialização de testes</p>
				</div>
				<div class='consultoria-cards'>
					<CardHome
						title="Científica"
						img={cientifica}
						consult="Científica"
						content="Realizamos pesquisa científica na área de Psicometria aplicada à Psicologia e Educação, com ênfase em Testagem Adaptativa Computadorizada."
					></CardHome>
					<CardHome
						title="Consultoria"
						img={consultoria}
						consult="Consultoria"
						content="Realizamos consultoria nas áreas de análise de dados quantitativos, construção de instrumentos e desenvolvimento de plataformas de testes computadorizados"
					></CardHome>
					<CardHome
						title="Comercialização"
						img={comercializacao}
						consult="Comercialização"
						content="Disponibilizamos uma plataforma própria para incluir e aplicar testes computadorizados"
					></CardHome>
				</div>
			</div>
		);
	}
}