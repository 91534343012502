import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class Faq extends React.Component {
  render() {

    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>O que é CAT (Testagem Adaptativa Computadorizada)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle2'>
              A CAT é um formato de aplicação de testes desenvolvida a partir de estudos de diferentes áreas, como Psicologia, Educação e Computação. Nesse tipo de aplicação, o teste vai se adaptando à pessoa de acordo com sua resposta. O mecanismo estatístico por trás desse formato permite que a aplicação seja mais curta e precisa.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br></br>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Qual a região de atuação de vocês?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle2'>
              Nossa sede é em Itatiba, interior de São Paulo. Porém, como nossas atividades são feitas on-line, fazemos parceria com profissionais de todo o Brasil e exterior.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br></br>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Como funciona a consultoria de vocês?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle2'>
              Atuamos em diferentes frentes que envolvem a análise de dados quantitativos, em especial na área da psicometria. Além disso, pensamos soluções para construção e aplicação de instrumentos psicológicos e educacionais em computador.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br></br>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography>Como funciona a área de estudo científicos do CATvante?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle2'>
            Nós desenvolvemos pesquisas, analisamos dados e divulgamos em congressos e artigos científicos estudos na área de Psicometria aplicada à Psicologia e Educação, com ênfase em Testagem Adaptativa Computadorizada.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br></br>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>Vocês auxiliam mestrandos, doutorandos e pesquisadores com seus projetos sobre psicometria e testes computadorizados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='subtitle2'>
            Sim! Podemos ser parceiros desde a elaboração de um projeto até a escrita de um texto científico, passando pela coleta e análise de dados.
            </Typography>
          </AccordionDetails>
        </Accordion>
        
      </div>
    );
  }
}