import * as React from 'react';
import { Typography, Button } from '@mui/material';
import missao from './missao.png'
import visao from './visao.png'
import valores from './valores.png'
import './missao.css'


export default class Missao extends React.Component {
  render() {
    return (
      <div class='missao'>
        <div class='missao-content'>
          <img src={missao} alt='Missão'></img>
          <Typography variant='h4'>Missão</Typography>
          <Typography variant="body1">Promover inovações em testagens computadorizadas com tecnologia e conhecimento científico de ponta, a fim de ampliar para a sociedade o acesso justo a esses avanços.</Typography>
        </div>
        <div class='missao-content'>
          <img src={visao} alt='Visão'></img>
          <Typography variant='h4'>Visão</Typography>
          <Typography variant="body1">Ser referência em Testagem Adaptativa Computadorizada (CAT) no Brasil.</Typography>
        </div>
        <div class='missao-content'>
          <img src={valores} alt='Valores'></img>
          <Typography variant='h4'>Valores</Typography>
          <Typography variant="body1">Transparência</Typography>
          <Typography variant="body1">Evidências Científicas</Typography>
          <Typography variant="body1">Inovação</Typography>
          <Typography variant="body1">Leveza</Typography>
          <Typography variant="body1">Equidade</Typography>
        </div>
      </div>
    );
  }
}