import React from "react";
import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./home/Home";
import About from "./about/about";

export default function Router() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
      </Routes>
    </HashRouter>
  )
}