import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FastForward } from '@mui/icons-material';
import './cardHome.css'
import { height } from '@mui/system';

export default class CardHome extends React.Component {
	render() {
		return (
			<div class='CardHome'>
				<Card sx={{ maxWidth: 345, minHeight: 550 }}>
					<><CardMedia
						sx={{ height: 300 }}
						image={this.props.img}
						title={this.props.title} /><CardContent>
							<Typography gutterBottom variant="h5" component="div">
								{this.props.consult}
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{this.props.content}
							</Typography>
						</CardContent><CardActions>
							<Button variant="contained" endIcon={<FastForward />}>
								Saiba mais
							</Button>
						</CardActions></>
				</Card>
			</div>
		);
	}
}