import * as React from 'react';
import { Typography} from '@mui/material';
import felipe from './felipe.png'
import arae from './arae.png'
import alexandre from './alexandre.png'
import gustavo from './gustavo.png'
import './time.css'


export default class Time extends React.Component {
  render() {
    return (
      <div>
        <Typography variant='h3'>Nosso Time</Typography>
        <div class='time'>
        <div class='time-content'>
          <img src={felipe} alt='Foto de Felipe Dinardi'></img>
          <Typography variant='h5'>Felipe Dinardi</Typography>
          <Typography variant="body1">Mestrando em Psicologia com ênfase em Avaliação Psicológica no contexto de Trabalho e Carreira pela Universidade São Francisco. Graduado em Ciências da Computação e Pós-Graduado em Data Science/Big Data, com mais de 14 anos de experiência profissional.</Typography>
        </div>
        <div class='time-content'>
          <img src={arae} alt='Foto de Araê Cainã'></img>
          <Typography variant='h5'>Araê Cainã</Typography>
          <Typography variant="body1">Psicólogo, doutorando em Psicologia pelo Programa de Pós-Graduação da Universidade São Francisco (PPG-USF - nota 7 CAPES). Atua na área de Psicologia Social, do Trabalho e da Educação. Atualmente é Diretor de Projetos Sociais na Zanity – Análise de Dados & Consultoria, onde cria instrumentos para mapeamento de situações de risco, entre outras atividades. Nos últimos anos vem se dedicando a criação de estratégias de mapeamento em larga escala, auxiliando no desenvolvimento de plataformas e ferramentas, como o Sistema de Monitoramento da Qualidade da Educação do Estado do Ceará, trabalho realizado junto ao Laboratório de Estudos e Pesquisas em Economia Social (LEPES/USP).</Typography>
        </div>
        <div class='time-content'>
          <img src={alexandre} alt='Foto de Alexandre Jaloto'></img>
          <Typography variant='h5'>Alexandre Jaloto</Typography>
          <Typography variant="body1">Biólogo, mestre em Educação em Ciências e Saúde (UFRJ) e doutorando em Psicologia na Universidade São Francisco. É psicometrista com mais de 12 anos de experiência em avaliação educacional em larga escala. Seus temas de pesquisa são psicometria, avaliação educacional e medidas em educação. É pesquisador voluntário no CATvante e atua na frente científica.</Typography>
        </div>
        <div class='time-content'>
          <img src={gustavo} alt='Foto de Gustavo H. Martins'></img>
          <Typography variant='h5'>Gustavo H. Martins</Typography>
          <Typography variant="body1">Psicólogo, Mestre e Doutorando em Psicologia pela Universidade São Francisco com ênfase em Avaliação Psicológica. Professor de Psicologia da Faculdade de Extrema (FAEX). Atua como Assistente Editorial da Revista Brasileira de Orientação Profissional (Qualis A2). Membro da diretoria executiva da Associação Brasileira de Orientação Profissional (ABOP), gestão 2022-2023. Possui mais de 20 artigos e capítulos de livros publicados nas áreas de Psicometria, Orientação Profissional e de Carreira e Testagem Adaptativa Computadorizada.</Typography>
        </div>
      </div>
      </div>
      
    );
  }
}