import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import './navbar.css'
import catvante from './catvante.png'
import { Link } from 'react-router-dom';


export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            <img 
                src={catvante}
                class="navbar-img"
            >
            </img>
          <Link to='/' class='bt-link' style={{ textDecoration: 'none' }} color="inherit"><Button className='button' color="inherit">Início</Button></Link>
          <Link to='/about' class='bt-link' style={{ textDecoration: 'none' }} color="inherit"><Button className='button' color="inherit">Sobre</Button></Link>
          {/* <Link to='/about' class='bt-link' style={{ textDecoration: 'none' }} color="inherit"><Button className='button' color="inherit">Atuações</Button></Link> */}
          <a href='https://wa.me/5519981553910?text=Ol%C3%A1!%0A%0AGostaria%20de%20agendar%20uma%20reuni%C3%A3o%20com%20o%20CATvante.' class='bt-link' style={{ textDecoration: 'none' }} color="inherit" target='_blank'><Button className='button' color="inherit">Agende uma Reunião</Button></a>
          {/* <Link to='/about' class='bt-link' style={{ textDecoration: 'none' }} color="inherit"><Button className='button' color="inherit">Login</Button></Link> */}
          <a href='https://admin.catvante.com.br' class='bt-link' style={{ textDecoration: 'none' }} color="inherit" target='_blank'><Button className='button' color="inherit">Aplicador</Button></a>
          <a href='https://plataforma.catvante.com.br' class='bt-link' style={{ textDecoration: 'none' }} color="inherit" target='_blank'><Button className='button' color="inherit">Respondente</Button></a>
        </Toolbar>
      </AppBar>
    </Box>
  );
}