import * as React from 'react';
import { Typography, Button} from '@mui/material';
import './agende.css'


export default class Agende extends React.Component {
	render() {
		return (
			<div class='agende'>
          <Typography variant='h3'>Agende uma reunião catvante<strong>.</strong></Typography>
          
          <a href='https://wa.me/5519981553910?text=Ol%C3%A1!%0A%0AGostaria%20de%20agendar%20uma%20reuni%C3%A3o%20com%20o%20CATvante.' class='bt-link' style={{ textDecoration: 'none' }} color="inherit" target='_blank'>
            <Button id='envieMensagem' color="inherit">
            Envie uma mensagem
            </Button>
          </a>
          <Button variant='text' >
            
          </Button>
          
          

        </div>
		);
	}
}