import React from "react";
import { Card } from "@mui/material";
import './carouselCard.css'

export default class CarouselCard extends React.Component {
    render() {
        return (
            <Card>
                <div class='carousel-card'>
                <div class='balao'>
                    <i>{this.props.depoimento}</i>
                </div>
                <div class = 'text'>
                <strong>{this.props.nome}</strong>
                <p>{this.props.empresa}</p>
                </div>

                </div>
                
            </Card>
        )

    }
}