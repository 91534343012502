import React from "react";
import Typography from '@mui/material/Typography';
import { Phone, ContactMail, LinkedIn, Instagram } from "@mui/icons-material";
import { Divider } from "@mui/material";

import { Link } from "react-router-dom";

import './footer.css'
import logoBranco from './catvante_logo_branco.png'



export default class Footer extends React.Component {

  render() {
    return (
      <div class="footer-geral">
        <div class="footer">
          <div class="content-footer">
            <img src={logoBranco}></img>
            <div class='contact-info'>
              <Phone ></Phone>
              <Typography variant="contained">
                +55 19 98155-3910
              </Typography>
            </div>
            <div class='contact-info'>
              <Link href='mailto:contato@catvante.com.br' target='_blank'>
                <ContactMail ></ContactMail>
              </Link>

              <Typography variant="contained">
                contato@catvante.com.br
              </Typography>
            </div>
            <div class='contact-info'>
              <Link href='https://www.linkedin.com/company/catvante-oficial/' target='_blank'>
                <LinkedIn ></LinkedIn>
              </Link>

              <Typography variant="contained">
                Página do LinkedIn
              </Typography>
            </div>
            <div class='contact-info'>
              <Link href='https://www.instagram.com/catvante.oficial/' target='_blank'>
                <Instagram ></Instagram>
              </Link>

              <Typography variant="contained">
                Página do LinkedIn
              </Typography>
            </div>

          </div>
          <div class="content-footer">
            <Typography variant="subtitle1">Home</Typography>
            <Typography variant="subtitle1">Sobre</Typography>
            <Typography variant="subtitle1">Atuações</Typography>
            {/* <Typography variant="subtitle1">Contato</Typography> */}
            {/* <Typography variant="subtitle1">Blog</Typography> */}
            {/* <Typography variant="subtitle1">Política de privacidade</Typography> */}
          </div>

        </div>
        <Divider sx={{ bgcolor: "white" }}></Divider>
        <div class="bottom-footer">
          {/* <Link underline="none">Política de Privacidade</Link> */}
          <Typography>Copyright ©2022, Catvante. Todos os direitos reservados.</Typography>
        </div>
      </div>


    );
  }
};