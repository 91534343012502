import * as React from 'react';
import Navbar from '../navbar/navbar';
import './home.css'
import { Button } from '@mui/material';
import HomeCarousel from '../components/carousel/carousel';
import Faq from '../components/faq/faq';
import Typography from '@mui/material/Typography';
import Footer from '../components/footer/footer';
import CardsConsultoria from '../components/cardsConsultoria/cardsConsultoria';
import quem from './quem.png'
import faqImage from './faq.png'
import Agende from '../components/agende/agende';
import { Link } from 'react-router-dom';

export default class Home extends React.Component{
  render() {
    return(
      <React.Fragment>
      <Navbar/>
      <div class="land">
        <div class="content">
          <h1>Promovemos soluções em testagem computadorizada com tecnologia e conhecimento científico de ponta</h1>
        </div>
        <div class="content">
        </div>
      </div>
      <CardsConsultoria/>
      <div class="quem">
        <div class="content-quem">
          <img id="img-quem" src={quem} alt="Homem negro usando um notebook"></img>
        </div>
        <div class="content-quem">
          <h3>Quem é o CATvante?</h3>
          <h2>Laboratório constituído por profissionais da psicologia, educação e tecnologia</h2>
          <br></br>
          <p>Iniciamos nossa história como um grupo interessado em compreender com real profundidade a CAT (Testagem Adaptativa Computadorizada) e logo notamos a desigualdade no acesso a testes psicológicos e educacionais de qualidade por parte do público em geral.</p>
          <p>Isso ocorre porque a construção de um instrumento de qualidade e sua aplicação têm altos custos. Por isso, muitas vezes é financeiramente inviável acessar e se beneficiar do uso de um teste psicológico ou educacional.</p>
          <p>O CATvante atua para que pesquisadores, estudantes de graduação e pós-graduação, psicólogos e educadores possam construir, aplicar e acessar testes de forma eficiente.</p>
          <p>Viemos para cativar.</p>
          <Link to='/about' id='bt-wpp' href='header' style={{ textDecoration: 'none' }} color="inherit"><Button className='button' color="inherit" variant="text" id="button-quem">Conheça mais sobre o CATvante</Button></Link>
          {/* <Button variant="text" id="button-quem">Conheça mais sobre o CATvante</Button> */}
        </div>
      </div>
      <HomeCarousel/>
        <h1 id='faq-h1'>Ficou alguma dúvida?</h1>
      <div class="faq">
        <div class="content-faq">
          <img id="img-faq" src={faqImage} alt="Mulher branca de cabelos ruivos com dúvida"></img>
        </div>
        <div class="content-faq">
          <Faq/>
        </div>
      </div>
      <Agende/>
      <Footer/>
      </React.Fragment>
      
      

    
    )
  }
}
// export default function BoxSx() {
//   return (

//     <><><Navbar>

//     </Navbar>
//       <div class="land">
//         <div class="content">
//           <h1>Promovemos soluções em testagem computadorizada com tecnologia e conhecimento científico de ponta</h1>
//         </div>
//         <div class="content">
//         </div>
//       </div>
//       <div class="consultoria">
//         <div class="consultoria-title">
//           <h1>Nossos Serviços</h1>
//           <p>Estudos científicos, consultoria para empresas e comercialização de testes</p>
//         </div>
//         <div class='consultoria-cards'>
//           <CardHome
//             title="Científica"
//             img='https://catvante.com.br/wp-content/uploads/2022/02/20220211-catvante-icons-ciencia-150x150.png'
//             consult="Científica"
//             content="Realizamos pesquisa científica na área de Psicometria aplicada à Psicologia e Educação, com ênfase em Testagem Adaptativa Computadorizada."
//           ></CardHome>
//           <CardHome
//             title="Consultoria"
//             img='https://catvante.com.br/wp-content/uploads/2022/02/20220211-catvante-icons-consultoria-150x150.png'
//             consult="Consultoria"
//             content="Realizamos consultoria nas áreas de análise de dados quantitativos, construção de instrumentos e desenvolvimento de plataformas de testes computadorizados"
//           ></CardHome>
//           <CardHome
//             title="Comercialização"
//             img='https://catvante.com.br/wp-content/uploads/2022/02/20220211-catvante-icons-comercializacao-150x150.png'
//             consult="Comercialização"
//             content="Disponibilizamos uma plataforma própria para incluir e aplicar testes computadorizados"
//           ></CardHome>
//         </div>
//       </div>
//       <div class="quem">
//         <div class="content-quem">
//           <img id="img-quem" src='https://catvante.com.br/wp-content/uploads/2022/02/20220211-catvante-sobre-nos-01.png' alt="Homem negro usando um notebook"></img>
//         </div>
//         <div class="content-quem">
//           <h3>Quem é o CATvante?</h3>
//           <h2>Laboratório constituído por profissionais da psicologia, educação e tecnologia</h2>
//           <br></br>
//           <p>Iniciamos nossa história como um grupo interessado em compreender com real profundidade a CAT (Testagem Adaptativa Computadorizada) e logo notamos a desigualdade no acesso a testes psicológicos e educacionais de qualidade por parte do público em geral.</p>
//           <p>Isso ocorre porque a construção de um instrumento de qualidade e sua aplicação têm altos custos. Por isso, muitas vezes é financeiramente inviável acessar e se beneficiar do uso de um teste psicológico ou educacional.</p>
//           <p>O CATvante atua para que pesquisadores, estudantes de graduação e pós-graduação, psicólogos e educadores possam construir, aplicar e acessar testes de forma eficiente.</p>
//           <p>Viemos para cativar.</p>
//           <Button variant="text" id="button-quem">Conheça mais sobre o CATvante</Button>
//         </div>
//       </div>
//       <HomeCarousel></HomeCarousel>
//         <h1 id='faq-h1'>Ficou alguma dúvida?</h1>
//       <div class="faq">
//         <div class="content-faq">
//           <img id="img-faq" src='https://catvante.com.br/wp-content/uploads/2022/02/20220216-catvante-faq-2.png' alt="Mulher branca de cabelos ruivos com dúvida"></img>
//         </div>
//         <div class="content-faq">
//           <Faq></Faq>
//         </div>
//       </div>
//       <div class='agende'>
//         <Typography variant='h3'>Agende uma reunião catvante<strong>.</strong></Typography>
//         <Button variant='text' id='envieMensagem'>
//           <Typography variant='subtitle1'>Envie uma mensagem</Typography>
//         </Button>
        
//       </div>
//       <Footer></Footer>
      

//     </></>

//   );
// }