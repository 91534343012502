import React from "react";
import Carousel from "react-elastic-carousel";
import CarouselCard from "./carouselCard/carouselCard";
import './carousel.css'

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

export default class HomeCarousel extends React.Component {

    render() {
        return (
            <>
                <h2 style={{ textAlign: "center" }}>O QUE FALAM DA GENTE</h2>
                <h1 style={{ textAlign: "center" }}>Depoimentos</h1>
                <div className="App">
                    <Carousel breakPoints={breakPoints}>
                        <CarouselCard
                            depoimento = 'Trabalhar com o CATvante tem sido uma ótima experiência com todo o conhecimento que eles possuem sobre teste adaptativo computadorizado, teoria de resposta ao item e avaliação educacional. Para quem precisa de uma avaliação personalizada, o CATvante é sua empresa.'
                            nome = 'Dario Cecilio-Fernandes'
                            empresa = 'Pesquisador da Faculdade de Ciências Médicas da Unicamp'
                        ></CarouselCard>
                            <CarouselCard
                                depoimento = 'O CATVante superou minhas expectativas positivas, como parceira, no desenvolvimento interativo da plataforma para aplicação online do SSPerform teste'
                                nome = 'Airton Cicchetto'
                                empresa = 'Autor do SSPerform Teste, Doutor em Psicologia, Mestre em Administração de Empresas e Engenheiro Industrial'
                            ></CarouselCard>
                        <CarouselCard
                            depoimento = 'Recomendo o CATvante. Além da qualidade do trabalho, atenção e competência para explicar os racionais e discutir soluções, eles têm uma excelente ética de trabalho. Cada vez mais presenciaremos a Psicologia e Psicometria impulsionando serviços e produtos digitais, e o CATvante será uma das responsáveis.'
                            nome = 'Filipe Gerude'
                            empresa = 'CEO da FutureMe - Projeto de Vida & Orientação Profissional'
                        ></CarouselCard>
                        <CarouselCard
                            depoimento = 'A consultoria em dados estatísticos do CATvante foi essencial para me ajudar com as análises do meu trabalho sobre ecologia animal! Desde o início eles foram super atenciosos e de grande conhecimento em estatística, colaborando muito para o enriquecimento do meu trabalho. Recomendo muito a consultoria do CATvante para todos aqueles que queiram a revisão e orientação de dados estatísticos!'
                            nome = 'Aline'
                            empresa = 'Bióloga'
                        ></CarouselCard>                        
                    </Carousel>
                </div>
            </>
        );
    }
};